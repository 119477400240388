<template>
	<div class="WorkOrder">
		<div class="WorkOrder-T">
			<div class="WorkOrderL">
				<Breadcrumb></Breadcrumb>
				<div class="RightsManagement-searchs">
					<div class="RightsManagement-searchs1">
						<inputs @inputs="inputValue" :inputShow="true" inputPlace="请输入名称"></inputs>
					</div>
					<div class="RightsManagement-searchs1">
						<el-select v-model="pages.T_handle" placeholder="请选择" @change="selectValue">
							<el-option v-for="item in options" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</div>
					<div class="WarningStrategyit-mai1-btn" style="margin-left: 10px;">
						<!-- <el-button type="danger" icon="el-icon-search">查询</el-button> -->
					</div>
				</div>
			</div>
			<div class="WorkOrderR">
				<div class="RightsManagement-searchsMai">
					<!-- <el-button type="danger" icon="el-icon-plus" @click="clickNew()">新增</el-button> -->
				</div>
			</div>
		</div>
		<div class="WorkOrder-B">
			<el-table :data="tableData" style="width: 100%;margin-bottom: 20px;" border :row-class-name="tableRowClassName">
				<template slot="empty">
					<noData></noData>
				</template>
				<el-table-column prop="T_Company_name" label="公司名称" :show-overflow-tooltip="true">
				</el-table-column>
				<el-table-column prop="T_name" label="提交人" :show-overflow-tooltip="true">
				</el-table-column>
				<el-table-column prop="T_phone" label="电话" :show-overflow-tooltip="true">
				</el-table-column>
				<el-table-column prop="T_title" label="标题" :show-overflow-tooltip="true">
				</el-table-column>
				<el-table-column prop="CreateTime" label="时间" :show-overflow-tooltip="true">
				</el-table-column>
				<el-table-column prop="T_State" label="状态" :show-overflow-tooltip="true" width="100">
					<template slot-scope="scope">
						<el-tag :type="scope.row.T_State==2?'danger':scope.row.T_State==3?'success':''">{{scope.row.T_State==1?'已处理':scope.row.T_State==3?'已回访':'待处理'}}</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="address" label="操作" fixed="right" width="210">
					<template slot-scope="scope">
						<el-button v-permission="'WorkOrder:Get'" size="small" @click="SetBinding(scope.row)">查看</el-button>
						<el-button v-permission="'WorkOrder:Get'" size="small" @click="showTimeline(scope.row)">记录</el-button>
						<el-button v-permission="'WorkOrder:Del'" size="small" @click="SetDel(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div style="margin-top: 50px;">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
					:current-page="pages.page" :page-size="pages.page_z"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>
		<el-drawer title="查看工单" :visible.sync="drawer" direction="rtl" :append-to-body="true">
			<div style="padding:0 20px;color: #000;">
				<el-descriptions title="工单信息" column="1">
					<el-descriptions-item label="标题">{{workObj.T_title}}</el-descriptions-item>
					<el-descriptions-item label="公司名称">{{workObj.T_name}}</el-descriptions-item>
					<el-descriptions-item label="提交人">{{workObj.T_Company_name}}</el-descriptions-item>
					<el-descriptions-item label="电话">{{workObj.T_phone?workObj.T_phone:'暂无'}}</el-descriptions-item>
					<el-descriptions-item label="处理状态">
						<el-tag v-if="workObj.T_State==1" size="small">已解决</el-tag>
						<el-tag v-else type="danger" size="small">未解决</el-tag>
					</el-descriptions-item>
					<el-descriptions-item label="工单详情">{{workObj.T_text!=''?workObj.T_text:'暂无工单详情可展示'}}</el-descriptions-item>
				</el-descriptions>

				<el-divider>操作工单</el-divider>
				<el-form ref="ruleForms" label-width="80px">
					<el-form-item label="工单状态">
						<el-select v-model="upData.T_handle" placeholder="请选择工单状态">
						<el-option label="已处理" value="1"></el-option>
						<el-option label="已回访" value="3"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item :label="upData.T_handle==1?'工单处理':'工单回访'">
						<el-input type="textarea" v-model="upData.T_info"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="onSubmit">立即提交</el-button>
						<el-button @click="drawer=false">取消</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-drawer>
		<el-drawer title="查看记录" :visible.sync="drawer1" direction="rtl" :append-to-body="true" size="50%">
			<div style="padding:0 20px;color: #000;">
				<timeline :workObj="workObj"></timeline>
			</div>
		</el-drawer>
	</div>
</template>

<script>
	import {
		WorkList,
		workDel,workUp
	} from "@/api/systemManagement/WorkOrder"
	import { WorkUserGet } from "@/api/systemManagement/WorkOrderUser"
	import timeline from '@/components/timeline'
	export default {
		components:{timeline},
		data() {
			return {
				pages: {
					T_name: '',
					T_handle: '',
					page: 1,
					page_z: 10,
				},
				upData:{
					Id:null,
					T_info:'',
					T_handle:'1'
				},
				options: [{
					value: '',
					label: '全部'
				},{
					value: '1',
					label: '已处理'
				}, {
					value: '2',
					label: '待处理'
				}, {
					value: '3',
					label: '已回访'
				}],
				drawer: false,
				drawer1: false,
				total: 50,
				tableData: [],
				workObj: {}
			}
		},
		mounted() {
			this.GetListApi()
		},
		methods: {
			async onSubmit(){
				const resit= await this.upworkUp()
				if (resit.data.Code==200) {
					this.$message.success('ok！操作成功！')
					this.drawer = false
				}
			},
			upworkUp(){
				return new Promise(resolve=>{
					workUp(this.upData).then(res=>{
						resolve(res)
					})
				})
			},
			tableRowClassName({row}) {
				if (row.T_State == 2) {
					return 'warning-row';
				}
				return '';
			},
			selectValue() {
				this.pages.page = 1
				this.GetListApi()
			},
			GetListApi() {
				WorkList(this.pages).then(res => {
					if (res.data.Code == 200) {
						this.tableData = res.data.Data.List
						this.total = res.data.Data.Num
					}
				})
			},
			async showTimeline(e){
				this.drawer1 = true
				var res = await this.GetshowApi(e)
				if (res.data.Code == 200) {
					this.workObj = res.data.Data
				}
			},
			GetshowApi(e) {//查看
				return new Promise(resolve => {
					WorkUserGet({ Id: e.Id }).then(res => {
						resolve(res)
					})
				})
			},



			SetBinding(e) { //查看
				this.workObj = e
				this.upData.Id = e.Id
				this.drawer = true
			},
			SetDel(e) {
				this.$confirm('此操作将执行删除, 是否继续?', '提示', {
					confirmButtonText: '确定删除',
					cancelButtonText: '我再想想',
					type: 'warning',
					center: true
				}).then(() => {
					workDel({
						Id: e.Id
					}).then(res => {
						if (res.data.Code === 200) {
							this.pages.page = 1
							this.GetListApi()
							this.$message.success('操作成功，已经删除')
						}
					})
				}).catch(() => {});
			},
			handleSizeChange(e) { //每页多少条
				this.pages.page_z = e
				this.GetListApi()
			},
			handleCurrentChange(e) { //跳转页
				this.pages.page = e
				this.GetListApi()
			},
			inputValue(e) {
				this.pages.T_name = e
				this.pages.page = 1
				this.GetListApi()
			},
		}
	}
</script>

<style lang="scss">
	.el-table .warning-row {
		background: rgba(250, 177, 177, .2);
	}

	.el-table .success-row {
		background: none;
	}

	.xian {
		border: 1px solid #DCDFE6;
		border-radius: 4px;
		padding: 0 15px;
		color: #606266;
		font-size: 14px;
		min-height: 42px;
	}

	.xianTxt {
		min-height: 50px;
		resize: vertical
	}

	.WorkOrder {
		.WorkOrder-T {
			display: flex;
			align-items: center;
			justify-content: space-between;
			background: rgba(#fff, 1);
			height: 70px;
			padding: 15px 10px;
			box-shadow: 5px 5px 10px -10px #000;

			.WorkOrderL {
				display: flex;
				align-items: center;

				.RightsManagement-searchs {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-left: 20px;

					.WorkOrderit-mai1-btn {}

					.RightsManagement-searchs1 {
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}

			.RightsManagement-searchsMai {
				display: flex;
				align-items: center;
				cursor: pointer;
			}
		}

		.WorkOrder-B {
			background: rgba(#fff, 1);
			margin: 10px;
			padding: 20px 30px;
		}
	}
</style>
