// 系统日志接口
import {
	axios
} from '../../utils/http.js'

const Api = {
	WorkUserList: '/v3/WorkOrder/User_List',//列表
	WorkUserAdd: '/v3/WorkOrder/Add',//添加工单
	WorkUserUp: '/v3/WorkOrder/Up',//已解决
	WorkUserGet: '/v3/WorkOrder/Get',//查看
	
}
//查看
export function WorkUserGet(data) {
	return axios({
		url: Api.WorkUserGet,
		method: 'post',
		data:data
		
	})
}
//已解决
export function WorkUserUp(data) {
	return axios({
		url: Api.WorkUserUp,
		method: 'post',
		data:data
		
	})
}
//添加工单
export function WorkUserAdd(data) {
	return axios({
		url: Api.WorkUserAdd,
		method: 'post',
		data:data
		
	})
}
//列表
export function WorkUserList(data) {
	return axios({
		url: Api.WorkUserList,
		method: 'post',
		data:data
	})
}
