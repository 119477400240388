<!-- 查看工单 -->
<template>
    <div class="">
        <el-timeline>
            <el-timeline-item :timestamp="workObj.CreateTime" placement="top" color="rgb(246, 156, 156)" icon="el-icon-more"
                size="large">
                <el-card style="line-height: 25px;">
                    <p style="font-weight: 600;">工单发布</p>
                    <p style="">标题: {{ workObj.T_title }}</p>
                    <p style="">内容：{{ workObj.T_text }}</p>
                    <p style="">操作人：{{ workObj.T_Company_name }}-{{ workObj.T_name }}</p>
                </el-card>
            </el-timeline-item>
            <template v-if="workObj.T_deal_with">
                <el-timeline-item v-for="(item1, index) in workObj.T_deal_with" :key="index + '-only'" :timestamp="item1.T_time"
                    placement="top" color="#46C7FF" icon="el-icon-more" size="large">
                    <el-card style="line-height: 25px;">
                        <p style="font-weight: 600;">工单处理</p>
                        <p style="">内容：{{ item1.T_text }}</p>
                        <p style="">操作人：{{ item1.T_Company_name }}-{{ item1.T_name }}</p>
                    </el-card>
                </el-timeline-item>
            </template>
            <template v-if="workObj.T_callback">
                <el-timeline-item v-for="(item2, index) in workObj.T_callback" :key="index + '-label'" :timestamp="item2.T_time"
                    placement="top" color="#10FF00" icon="el-icon-more" size="large">
                    <el-card style="line-height: 25px;">
                        <p style="font-weight: 600;">工单回访</p>
                        <p style="">内容：{{ item2.T_text }}</p>
                        <p style="">操作人：{{ item2.T_Company_name }}-{{ item2.T_name }}</p>
                    </el-card>
                </el-timeline-item>
            </template>
        </el-timeline>
    </div>
</template>

<script>
export default {
    name: "",
    props:['workObj'],
}
</script>