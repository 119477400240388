// 系统日志接口
import {
	axios
} from '../../utils/http.js'

const Api = {
	WorkList: '/v3/WorkOrder/List',//列表
	workDel:'/v3/WorkOrder/Del',//删除
	workUp:'/v3/WorkOrder/Up'//更新
}
//更新
export function workUp(data) {
	return axios({
		url: Api.workUp,
		method: 'post',
		data:data
		
	})
}
//删除
export function workDel(data) {
	return axios({
		url: Api.workDel,
		method: 'post',
		data:data
		
	})
}
//列表
export function WorkList(data) {
	return axios({
		url: Api.WorkList,
		method: 'post',
		data:data
	})
}
